import React from "react"

import LandingBio from "../components/landing-bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const EventsPage = () => (
  <Layout>
    <SEO title="Speaking" keywords={[`amy conroy`, `legal engineer`, `events`]} />
    <h4> Events I have previously spoken at include: </h4>
    <div>
      <h4><a href = "https://www.legalinnovators.co.uk">Legal Innovators UK</a></h4>
      <p>November 2023. The Future of Legal Innovation is Data Science.</p>
    </div>
    <div>
      <h4><a href = "https://www.bristol.ac.uk/study/postgraduate/taught/llm-law-innovation--technology/">University of Bristol</a></h4>
      <p>November 2023. Guest Lecture (LLM in Law, Technology and Innovation): Data Driven Thinking.</p>
    </div>
    <div>
      <h4><a href = "https://biall.org.uk">BIALL Annual Conference 2023</a></h4>
      <p>June 2023. A Practical Framework for Legal Innovation Training.</p>
    </div>
    <div>
      <h4><a href = "http://learninginlaw.org.uk">Learning in Law Conference</a></h4>
      <p>May 2023. Teaching Legal Innovation.</p>
    </div>
    <div>
      <h4><a href = "https://www.mishcon.com/news/mdr-lab-incubator-showcase-event-featured-in-artificial-lawyer">MDR Lab</a></h4>
      <p>November 2022. Mishcon de Reya MDR Lab Demo Day.</p>
    </div>
    <div>
      <h4><a href = "https://ai4legal.linkeddata.es/">AI4Legal Workshop</a></h4>
      <p>October 2022. International Workshop on Artificial Intelligence Technologies for Legal Documents (AI4LEGAL)</p>
    </div>
    <div>
      <h4><a href = "https://www.mishcon.com/">Mishcon de Reya Open Day</a></h4>
      <p>October 2022. The Future of LegalTech.</p>
    </div>
    <div>
      <h4><a href = "https://imanage.com/">iManage Connect Live 2022</a></h4>
      <p>October 2022. Tech Adoption Do's and Don'ts.</p>
    </div>
    <div>
      <h4><a href = "https://uomdss.co.uk/">University of Manchester Data Science Society</a></h4>
      <p>October 2022. Women in Data Panel.</p>
    </div>
    <div>
      <h4><a href = "https://www.linkedin.com/company/qmlis/">Queen Mary's Legal Innovation Society</a></h4>
      <p>August 2022. Keeping an Open Mind in the Legal Industry.</p>
    </div>
    <div>
      <h4><a href = "www.thelegaltechvacscheme.com">The LegalTech Vacation Scheme</a></h4>
      <p>August 2021. Alternative Careers in Law Panel.</p>
    </div>
    <div>
      <h4><a href = "https://www.legalgeek.co/student-and-junior-lawyer-lawtech-fair-2021/">Legal Geek</a></h4>
      <p>July 2021. Legal Geek: Student and Junior Lawyer LawTech Fair.</p>
    </div>
    <div>
      <h4><a href = "https://www.youtube.com/watch?v=ES0VKqrz7Ug">Bristol & Bath LegalTech</a></h4>
      <p>May 2021. Meet the Legal Techs: A showcase of Bristol & Bath's legal innovators.</p>
    </div>
    <div>
      <h4><a href = "https://www.instagram.com/tv/CIQZZYupAOW/?utm_source=ig_web_copy_link">Careers in Legal Tech</a></h4>
      <p>Feb 2021. University of Bristol Law School.</p>
    </div>
    <div>
      <h4><a href = "https://jurix2020.law.muni.cz/">JURIX 2020</a></h4>
      <p>Nov 2020. 33rd International Conference on Legal Knowledge and Information Systems.</p>
    </div>
    <div>
      <h4><a href = "https://www.eventbrite.co.uk/e/legaltech-bristols-next-global-tech-success-story-tickets-126073829217">Bristol Technology Festival</a></h4>
      <p>Nov 2020. LegalTech – Bristol’s next global tech success story.</p>
    </div>
  </Layout>
)

export default EventsPage
